<template>
    <div>
      <b-row class="match-height">
          
      <b-col xl="4" md="4" sm="6">
        <settings-card color="primary" icon="UploadIcon" link="importExcelInventory" statistic="Import Excel Inventory" statistic-title="importExcelInventory" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="success" icon="UploadIcon" link="importExcelPurchase" statistic="Import Excel Purchase" statistic-title="importExcelPurchase" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="danger" icon="UploadIcon" link="importExcelSale" statistic="Import Excel Sale" statistic-title="importExcelSale" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="info" icon="UploadIcon" link="importExcelPurchaseOrder" statistic="Import Excel Purchase Order" statistic-title="importExcelPurchaseOrder" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="warning" icon="UploadIcon" link="importExcelSaleOrder" statistic="Import Excel Sale Order" statistic-title="importExcelSaleOrder" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="info" icon="UploadIcon" link="importExcelAdjustment" statistic="Import Excel Adjustment" statistic-title="importExcelAdjustment" />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card color="success" icon="UserPlusIcon" link="importExcelCustomer" statistic="Import Excel Customer" statistic-title="importExcelCustomer" />
      </b-col>
   
      </b-row>
    </div>
  </template>
  
  <script>
  import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination } from "bootstrap-vue";
  
  import useUserList from "../useSettings";
  
  import store from "@/store";
  import { avatarText } from "@core/utils/filter";
  import { onUnmounted } from "@vue/composition-api";
  import settingsStoreModule from "../settingsStoreModule";
  import SettingsCard from "@core/components/statistics-cards/SettingsCard.vue";
  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      BTable,
      BFormSelect,
      BPagination,
  
      SettingsCard,
    },
  
    methods: {},
  
    setup() {
      const SETTINGS_APP_STORE_MODULE_NAME = "settings";
      // Register module
      if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
      });
  
      const { loading } = useUserList();
  
      return {
        loading,
        // Filter
      };
    },
    mounted() {},
  };
  </script>
  